body {
  margin: 0;
  background-color: #f1f1f1;
  font-family: "Inter" ,-apple-system,
  sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes marquee {
  0% {
            transform: translateX(0);

       -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
            transform: translateX(-488px);

       -moz-transform: translateX(-488px);
    -webkit-transform: translateX(-488px);
  }
}